import React from "react";
import IdleTimer from "react-idle-timer";
import {useNavigate} from "react-router-dom";

export const IdleWrapper = () => {
    const navigate = useNavigate();

    return (
        <IdleTimer
            timeout={1000 * 60}
            onIdle={() => navigate('/')}
        />
    );
};